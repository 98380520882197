import React from "react"
import { Link } from "gatsby"

// UI
import SEO from "~components/seo"
import Header from "~components/Header/Header"
import Headline from "~ui/Headline"
import SectionFooter from "~components/SectionFooter"

const TermsPage: React.FC = () => {
  return (
    <>
      <SEO
        canonicalTag="https://canopyservicing.com/terms/"
        description="Canopy Servicing's Terms of Use govern the use of our loan management and servicing platform and represent a binding contract."
        title="Terms of Service | Canopy Servicing"
      />
      <Header />
      <div className="px-6 py-16 mx-auto max-w-4xl fs-unmask">
        <Headline level={2} className="text-2xl md:text-4xl">
          Terms of Service
        </Headline>
        <p className="mb-6">
          <strong>Effective date: October 14, 2020</strong>
        </p>
        <p className="mb-6">
          PLEASE NOTE THAT YOUR USE OF AND ACCESS TO THE SERVICES (DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS; IF
          YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
        </p>
        <p className="mb-6">
          Welcome to Canopy. Please read on to learn the rules and restrictions that govern your use of our website(s),
          products, services and applications (the “Services”). If you have any questions, comments, or concerns
          regarding these terms or the Services, please contact us at{" "}
          <a href="mailto:privacy@canopyservicing.com">privacy@canopyservicing.com</a>.
        </p>
        <p className="mb-6">
          The Terms of Use (the “Terms”) are a binding contract between you and CANOPY TECHNOLOGY CORP. (“Company,” “we”
          and “us”). You must agree to and accept all of the Terms, or you don’t have the right to use the Services.
          Your using the Services in any way means that you agree to all of the Terms, and the Terms will remain in
          effect while you use the Services. The Terms include the provisions in this document, as well as those in the
          Privacy Policy.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          1. Change in Terms
        </Headline>
        <p className="mb-6">
          We are constantly trying to improve the Services, so the Terms may need to change along with the Services. We
          reserve the right to change the Terms at any time and, if required by applicable law, will bring it to your
          attention by placing a notice on the canopyservicing.com website, by sending you an email, and/or by some
          other means.
        </p>
        <p className="mb-6">
          If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no
          longer be able to use the Services. If you use the Services in any way after a change to the Terms is
          effective, that means you agree to all of the changes.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          2. Additional Terms
        </Headline>
        <p className="mb-6">
          Some of the Services may be subject to additional terms and conditions, which are posted on our website or
          made available separately from the Terms (“Additional Terms”). Your use of the Services may also be subject to
          additional policies, guidelines or rules we also post or make available. Such Additional Terms policies,
          guidelines and rules are incorporated and form a part of the Terms. If there is a conflict between the Terms
          and the Additional Terms, the Additional Terms will control.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          3. Privacy
        </Headline>
        <p className="mb-6">
          Company takes the privacy of its users very seriously. For the current Company Privacy Policy, please{" "}
          <Link to="/privacy/">click here</Link>.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          4. Basic Terms
        </Headline>
        <p className="mb-6">
          You may be required to sign up for an account, and select a password and user name (“Company User ID”). You
          promise to provide us with accurate, complete, and updated registration information about yourself. You may
          not select as your Company User ID a name that you don’t have the right to use, or another person’s name with
          the intent to impersonate that person. You may not transfer your account to anyone else without our prior
          written permission.
        </p>
        <p className="mb-6">
          You represent and warrant that you are of legal age to form a binding contract (or if not, you’ve received
          your parent’s or guardian’s permission to use the Services and gotten your parent or guardian to agree to the
          Terms on your behalf). If you’re agreeing to the Terms on behalf of an organization or entity, you represent
          and warrant that you are authorized to agree to the Terms on that organization or entity’s behalf and bind
          them to the Terms (in which case, the references to “you” and “your” in the Terms, except for in this
          sentence, refer to that organization or entity).
        </p>
        <p className="mb-6">
          You will only use the Services for your own internal, personal, non-commercial use, and not on behalf of or
          for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If
          your use of the Services is prohibited by applicable laws, then you aren’t authorized to use the Services. We
          can’t and won’t be responsible for your using the Services in a way that breaks the law.
        </p>
        <p className="mb-6">
          By submitting information, data, passwords, usernames, PINs, other log-in information, materials and other
          content to Company through the Services (“Account Information”), you are licensing that content to Company for
          the purpose of providing the Services. Company may use and store the content in accordance with this Agreement
          and our Privacy Policy. You represent that you are entitled to submit it to Company for use for this purpose,
          without any obligation by Company to pay any fees or be subject to any restrictions or limitations. By using
          the Services, you expressly authorize Company to access your Account Information maintained by identified
          third parties, on your behalf as your agent. Company will submit information including usernames and passwords
          that you provide. You authorize and permit Company to use and store information submitted by you to accomplish
          the foregoing and to configure the Services so that it is compatible with the third party sites for which you
          submit your information. You grant Company a limited power of attorney, and appoint Company as your
          attorney-in-fact and agent, to access third party sites, retrieve and use your information with the full power
          and authority to do and perform each thing necessary in connection with such activities, as you could do in
          person. YOU ACKNOWLEDGE AND AGREE THAT WHEN COMPANY IS ACCESSING AND RETRIEVING ACCOUNT INFORMATION FROM THIRD
          PARTY SITES, COMPANY IS ACTING AS YOUR AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF THE THIRD PARTY THAT
          OPERATES THE THIRD PARTY SITE. You are responsible for the accuracy and completeness of any Account
          Information provided to Company and Company will not be liable in the event that such Account Information is
          not accurate or complete.
        </p>
        <p className="mb-6">
          You will not share your account or password with anyone, and you must protect the security of your account and
          your password. You’re responsible for any activity associated with your account.
        </p>
        <p className="mb-6">
          You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those
          terms is defined below) or otherwise use the Services or interact with the Services in a manner that:
        </p>

        <ol type="a">
          <li>
            Infringes or violates the intellectual property rights or any other rights of anyone else (including
            Company);
          </li>
          <li>Violates any law or regulation;</li>
          <li>
            Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;
          </li>
          <li>
            Jeopardizes the security of your Company account or anyone else’s (such as allowing someone else to log in
            to the Services as you);
          </li>
          <li>
            Attempts, in any manner, to obtain the password, account, or other security information from any other user;
          </li>
          <li>Violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
          <li>
            Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or
            are activated while you are not logged into the Services, or that otherwise interfere with the proper
            working of the Services (including by placing an unreasonable load on the Services’ infrastructure);
          </li>
          <li>
            “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content
            (through use of manual or automated means);
          </li>
          <li>Copies or stores any significant portion of the Content; or</li>
          <li>
            Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or
            information of or relating to the Services.
          </li>
        </ol>

        <p className="mb-6">
          A violation of any of the foregoing is grounds for termination of your right to use or access the Services.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          5. Company’s Intellectual Property
        </Headline>
        <p className="mb-6">
          The materials displayed or performed or available on or through the Services, including text, graphics, data,
          articles, photos, images, illustrations, User Submissions, and so forth (all of the foregoing, the “Content”)
          are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright
          notices, trademark rules, information, and restrictions contained in any Content you access through the
          Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute,
          perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (a)
          without the prior consent of the owner of that Content or (b) in a way that violates someone else’s (including
          Company’s) rights.
        </p>
        <p className="mb-6">
          You understand that Company owns the Services. You won’t modify, publish, transmit, participate in the
          transfer or sale of, reproduce (except as expressly provided in this Section), creative derivative works based
          on, or otherwise exploit any of the Services.
        </p>
        <p className="mb-6">
          The Services may allow you to copy or download certain Content; please remember that just because this
          functionality exists, doesn’t mean that all the restrictions above don’t apply – they do!
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          6. User Submissions
        </Headline>
        <p className="mb-6">
          Anything you upload, share, store, or otherwise provide through the Services is your “User Submission.” In
          order to display your User Submissions on the Services, and to allow other users to enjoy them (where
          applicable), you grant us certain rights in those User Submissions. Please note that all of the following
          licenses are subject to our Privacy Policy to the extent they relate to User Submissions that are also your
          personally-identifiable information. For all User Submissions, you hereby grant Company a license to
          translate, modify (for technical purposes, for example making sure your content is viewable on an iPhone as
          well as a computer) and reproduce and otherwise act with respect to such User Submissions, in each case to
          enable us to operate the Services, as described in more detail below. This is a license only – your ownership
          in User Submissions is not affected.
        </p>
        <p className="mb-6">
          If you store a User Submission in your own personal Company account, in a manner that is not viewable by any
          other user except you (a “Personal User Submission”), you grant Company the license above, as well as a
          license to display, perform, and distribute your Personal User Submission for the sole purpose of making that
          Personal User Submission accessible to you and providing the Services necessary to do so.
        </p>
        <p className="mb-6">
          If you share a User Submission only in a manner that only certain specified users can view (for example, a
          private message to one or more other individuals who are involved with your application or use of the
          Services) (a “Limited Audience User Submission”), then you grant Company the licenses above, as well as a
          license to display, perform, and distribute your Limited Audience User Submission for the sole purpose of
          making that Limited Audience User Submission accessible to such other specified users, and providing the
          Services necessary to do so. Also, you grant such other specified users a license to access that Limited
          Audience User Submission, and to use and exercise all rights in it, as permitted by the functionality of the
          Services.
        </p>
        <p className="mb-6">
          If you share a User Submission publicly on the Services and/or in a manner that more than just you or certain
          specified users can view, (a “Public User Submission”), then you grant Company the licenses above, as well as
          a license to display, perform, and distribute your Public User Submission for the purpose of making that
          Public User Submission accessible to all Company users and providing the Services necessary to do so, as well
          as all other rights necessary to use and exercise all rights in that Public User Submission in connection with
          the Services and/or otherwise in connection with Company’s business for any purpose. Also, you grant all other
          users of the Services a license to access that Public User Submission, and to use and exercise all rights in
          it, as permitted by the functionality of the Services.
        </p>
        <p className="mb-6">
          You agree that the licenses you grant are royalty-free, perpetual, sublicensable, irrevocable, and worldwide,
          provided that when you delete your Company account, we will stop displaying your User Submissions (other than
          Public User Submissions, which may remain fully available) to other users (if applicable), but you understand
          and agree that it may not be possible to completely delete that content from Company’s records, and that your
          User Submissions may remain viewable elsewhere to the extent that they were copied or stored by other users.
        </p>
        <p className="mb-6">
          You understand and agree that Company, in performing the required technical steps to provide the Services to
          our users (including you), may need to make changes to your User Submissions to conform and adapt those User
          Submissions to the technical requirements of connection networks, devices, services, or media, and the
          foregoing licenses include the rights to do so.
        </p>
        <p className="mb-6">
          We own any feedback, suggestions, improvements, enhancements, and/or feature requests you make relating to the
          Services.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          7. Third Party Content
        </Headline>
        <p className="mb-6">
          Any information or content publicly posted or privately transmitted through the Services is the sole
          responsibility of the person from whom such content originated, and you access all such information and
          content at your own risk, and we aren’t liable for any errors or omissions in that information or content or
          for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any
          action regarding how you may interpret and use the Content or what actions you may take as a result of having
          been exposed to the Content, and you hereby release us from all liability for you having acquired or not
          acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in
          using the Services and are not responsible for which users gain access to the Services.
        </p>
        <p className="mb-6">
          You are responsible for all Content you contribute, in any manner, to the Services, and you represent and
          warrant you have all rights necessary to do so, in the manner in which you contribute it. You will keep all
          your registration information accurate and current. You are responsible for all your activity in connection
          with the Services.
        </p>
        <p className="mb-6">
          The Services may contain links or connections to third party websites or services that are not owned or
          controlled by Company. When you access third party websites or use third party services, you accept that there
          are risks in doing so, and that Company is not responsible for such risks. We encourage you to be aware when
          you leave the Services and to read the terms and conditions and privacy policy of each third party website or
          service that you visit or utilize. For example, if you link your third party accounts (bank account, tax
          information, etc.) to the Services, what information we collect will be governed by the terms and privacy
          policies of those third parties. By linking those accounts to your Canopy account, you are granting Company
          the right to collect the information provided by such third part websites or services.
        </p>
        <p className="mb-6">
          Company has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or
          practices of or opinions expressed in any third party websites or by any third party that you interact with
          through the Services. In addition, Company will not and cannot monitor, verify, censor or edit the content of
          any third party site or service. By using the Services, you release and hold us harmless from any and all
          liability arising from your use of any third party website or service.
        </p>
        <p className="mb-6">
          Your interactions with organizations and/or individuals found on or through the Services, including payment
          and delivery of goods or services, and any other terms, conditions, warranties or representations associated
          with such dealings, are solely between you and such organizations and/or individuals. You should make whatever
          investigation you feel necessary or appropriate before proceeding with any online or offline transaction with
          any of these third parties. You agree that Company will not be responsible or liable for any loss or damage of
          any sort incurred as the result of any such dealings.
        </p>
        <p className="mb-6">
          If there is a dispute between participants on this site, or between users and any third party, you agree that
          Company is under no obligation to become involved. In the event that you have a dispute with one or more other
          users, you release Company, its officers, employees, agents, and successors from claims, demands, and damages
          of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of
          or in any way related to such disputes and/or the Services. If you are a California resident, you will and
          hereby do waive California Civil Code Section 1542, which says: “A general release does not extend to claims
          which the creditor does not know or suspect to exist in his favor at the time of executing the release, which,
          if known by him must have materially affected his settlement with the debtor.”
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          8. Changes to the Services
        </Headline>
        <p className="mb-6">
          We’re always trying to improve the Services, so they may change over time. We may suspend or discontinue any
          part of the Services, or we may introduce new features or impose limits on certain features or restrict access
          to parts or all of the Services. We may try to give you notice when we make a material change to the Services
          that would adversely affect you, but this isn’t always practical and we will not be obligated to notify you
          unless required by applicable law. Similarly, we reserve the right to remove any Content from the Services at
          any time, for any reason (including if someone alleges you contributed that Content in violation of the
          Terms), in our sole discretion, and without notice.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          9. Termination
        </Headline>
        <p className="mb-6">
          You may request to terminate your account by contacting us at{" "}
          <a href="mailto:privacy@canopyservicing.com">privacy@canopyservicing.com</a>. Please refer to our Privacy
          Policy, as well as the licenses above, to understand how we treat information you provide to us after you have
          stopped using the Services.
        </p>
        <p className="mb-6">
          Company is also free to terminate (or suspend access to) your use of the Services or your account, for any
          reason in our discretion, including your breach of the Terms. Company has the sole right to decide whether you
          are in violation of any of the restrictions set forth in the Terms.
        </p>
        <p className="mb-6">
          Account termination may result in destruction of any Content associated with your account, so keep that in
          mind before you decide to terminate your account.{" "}
        </p>
        <p className="mb-6">
          Provisions that, by their nature, should survive termination of the Terms will survive termination, including
          any obligations you owe us. By way of example, all of the following will survive termination: any obligation
          you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or
          intellectual property rights, and terms regarding disputes between us.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          10. Warranty Disclaimer
        </Headline>
        <p className="mb-6">
          We do not make any representations or warranties concerning any content contained in or accessed through the
          Services, and we will not be responsible or liable for the accuracy, copyright compliance, legality, or
          decency of material contained in or accessed through the Services. We make no representations or warranties
          regarding suggestions or recommendations of services or products offered or purchased through the Services.
          Products and services purchased or offered (whether or not following such recommendations and suggestions)
          through the Service, including the Services, s are provided “AS IS” and without any warranty of any kind from
          Company. THE SERVICES AND CONTENT ARE PROVIDED ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OR ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW
          LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          11. Limitation of Liability
        </Headline>
        <p className="mb-6">
          TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING
          TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) WILL COMPANY BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A)
          ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS,
          LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT,
          IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO COMPANY IN
          CONNECTION WITH THE SERVICES IN THE THREE (3) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (III) ANY
          MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES,
          SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          12. Indemnity
        </Headline>
        <p className="mb-6">
          To the fullest extent allowed by applicable law, you agree to indemnify and hold us, our affiliates, officers,
          agents, employees, and partners harmless from and against any and all claims, actions. liabilities, damages
          (actual and consequential), losses, fines, penalties, settlements, fees and expenses (including attorneys’
          fees) arising from, resulting from or in any way related to (a) your use of the Services (including any
          actions taken by a third party using your account), (b) your violation of the Terms, (c) your business or (d)
          your acts or omissions.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          13. Assignment
        </Headline>
        <p className="mb-6">
          You may not assign, delegate or transfer the Terms or your rights or obligations hereunder, or the Services
          account, in any way (by operation of law or otherwise) without Company’s prior written consent. We may
          transfer, assign, or delegate the Terms and our rights and obligations without consent.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          14. Choice of Law; Arbitration
        </Headline>
        <p className="mb-6">
          The Terms are governed by and will be construed under the laws of the State of California, without regard to
          the conflicts of laws provisions thereof. Any dispute arising from or relating to the subject matter of the
          Terms will be finally settled in San Diego County, California, in English, in accordance with the Streamlined
          Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. (“JAMS”) then in effect,
          by one commercial arbitrator with substantial experience in resolving intellectual property and commercial
          contract disputes, who will be selected from the appropriate list of JAMS arbitrators in accordance with such
          rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent
          jurisdiction. Notwithstanding the foregoing obligation to arbitrate disputes, each party will have the right
          to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction. For all
          purposes of the Terms, the parties consent to exclusive jurisdiction and venue in the state or federal courts
          located San Francisco County.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          15. Miscellaneous
        </Headline>
        <p className="mb-6">
          You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other
          governmental assessments associated with your activity in connection with the Services, provided that the
          Company may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The
          failure of either you or us to exercise, in any way, any right herein will not be deemed a waiver of any
          further rights hereunder. If any provision of the Terms is found to be unenforceable or invalid, that
          provision will be limited or eliminated, to the minimum extent necessary, so that the Terms will otherwise
          remain in full force and effect and enforceable. In addition to any Additional Terms, you and Company agree
          that the Terms are the complete and exclusive statement of the mutual understanding between you and Company,
          and that it supersedes and cancels all previous written and oral agreements, communications and other
          understandings relating to the subject matter of the Terms. No agency, partnership, joint venture, or
          employment is created as a result of the Terms and you do not have any authority of any kind to bind Company
          in any respect whatsoever. You and Company agree there are no third party beneficiaries intended under the
          Terms.
        </p>
      </div>

      <SectionFooter />
    </>
  )
}

export default TermsPage
